<template>
  <b-container>
    <el-card class="mt-5 pt-5 mx-auto">
      <el-form ref="info" :model="info" label-width="80px">
        <b-row>
          <b-col lg="5">
            <el-form-item label="编号">
              <el-input v-model="info.userId" />
            </el-form-item>
          </b-col>
          <b-col lg="5">
            <el-form-item label="姓名">
              <el-input v-model="info.name" />
            </el-form-item>
          </b-col>
          <b-col lg="5">
            <el-form-item label="擅长品类">
              <el-input v-model="info.category" />
            </el-form-item>
          </b-col>
          <b-col lg="5">
            <el-form-item label="国籍">
              <el-input v-model="info.nationality" />
            </el-form-item>
          </b-col>
          <b-col lg="5">
            <el-form-item label="年龄">
              <el-input v-model="info.age" type="number" />
            </el-form-item>
          </b-col>
          <b-col lg="5">
            <el-form-item label="母语">
              <el-input v-model="info.language" />
            </el-form-item>
          </b-col>
          <b-col lg="5">
            <el-form-item label="外语">
              <el-input v-model="info.otherLang" />
            </el-form-item>
          </b-col>
          <b-col lg="5">
            <el-form-item label="性别">
              <el-radio v-model="info.gender" label="0">男</el-radio>
              <el-radio v-model="info.gender" label="1">女</el-radio>
            </el-form-item>
          </b-col>
          <b-col lg="5">
            <el-form-item label="身高">
              <el-input v-model="info.height" type="number" />
            </el-form-item>
          </b-col>
          <b-col lg="5">
            <el-form-item label="尺码">
              <el-input v-model="info.size" />
            </el-form-item>
          </b-col>
          <b-col lg="5">
            <el-form-item label="平台">
              <el-radio v-model="info.platform" label="3">速卖通</el-radio>
              <el-radio v-model="info.platform" label="4">国际站</el-radio>
            </el-form-item>
          </b-col>
          <b-col lg="5">
            <el-form-item label="空播视频">
              <el-input v-model="info.video_live" />
            </el-form-item>
          </b-col>
        </b-row>
        <el-form-item label="照片">
          <el-upload
            class="w-50"
            action="uploadAction"
            :on-change="handleUploadPersonalImg"
            :auto-upload="false"
            multiple
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              1. 只能上传jpg/png文件，且不超过500kb <br>
              2. 第一张照片宽高比：4：3 <br>
              3. 第二张照片宽高比：3：4 <br>
              4. 其他照片无宽高比要求
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="介绍视频">
          <el-upload
            class="w-50"
            action="uploadAction"
            :limit="1"
            :on-change="handleUploadVideoIntro"
            :auto-upload="false"
            multiple
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </b-container>
</template>

<script>
import { upload } from '@/api/hosts'

export default {
  name: 'Upload',
  data() {
    return {
      uploadForm: new FormData(),
      info: {
        userId: '',
        name: '',
        category: '',
        nationality: '',
        age: null,
        language: '',
        otherLang: '',
        gender: null,
        height: null,
        size: '',
        platform: null,
        video_live: ''
      }
    }
  },
  methods: {
    onSubmit() {
      Object.keys(this.info).forEach(key => {
        if (this.info[key]) {
          this.uploadForm.append(key, this.info[key])
        }
      })
      this.uploadForm.append('accessKey', this.$route.query.accessKey.toString())
      upload(this.uploadForm).then(e => {
        console.log(e)
      })
    },
    handleUploadPersonalImg(file, fileList) {
      const name = 'pic_personal' + fileList.length
      this.uploadForm.append(name, file.raw)
    },
    handleUploadVideoIntro(file) {
      const name = 'video_intro'
      this.uploadForm.append(name, file.raw)
    }
  }
}
</script>

<style scoped>

</style>
