import request from '@/utils/request'

export function fetchList(params) {
  return request({
    url: '/hosts/getList',
    method: 'get',
    params
  })
}

export function fetchDetails(params) {
  return request({
    url: '/hosts/getDetails',
    method: 'get',
    params
  })
}

export function upload(data) {
  return request({
    url: '/hosts/upload',
    method: 'post',
    data
  })
}

export function fetchProp() {
  return request({
    url: '/hosts/getProp',
    method: 'get'
  })
}
